/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import {
  SUBMIT_ENTRY,
  EDIT_ENTRY,
  SUBMISSION_GRADED,
  SUBMISSION_UPLOAD_FAILURE,
  SUBMISSION_UPLOADED,
  RESET_SAVE_STATE,
} from '../actions/types';

export const INITIAL_STATE = {
  submissionContent: null,
  grade: {
    statistics: {},
    submission: '',
  },
  submissionError: null,
  shouldGrade: true,
  successfulSave: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_ENTRY:
      return {
        ...state,
        submissionContent: action.payload.content,
        shouldGrade: action.payload.shouldGrade,
      };
    case EDIT_ENTRY:
      return {
        ...state,
        grade: { statistics: {}, submission: '' },
        shouldGrade: true,
      };
    case SUBMISSION_GRADED:
      return { ...state, grade: action.payload };
    case SUBMISSION_UPLOAD_FAILURE:
      return { ...state, submissionError: action.payload, successfulSave: false };
    case SUBMISSION_UPLOADED:
      return { ...state, successfulSave: true };
    case RESET_SAVE_STATE:
      return { ...state, successfulSave: null };
    default:
      return { ...state };
  }
};
