/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PieChart, Pie, Cell } from 'recharts';

import ThinEditor from './ThinEditor';
import { SUBMISSION, ANSWERKEY } from '../constants';
import {resetSaveState, scoreSubmission, sendGradeToCanvas} from '../actions/submission';

import {
  loadSolution,
  setShowModal,
  setModalContent,
  returnToEditSubmission,
  setupKeyboardShortcuts,
  resizeIFrame,
  handleAudioLoaded,
} from '../actions/setup';

import AudioMetaBar from "./AudioMetaBar";
import LoadSpinner from "./LoadSpinner";
import FlashMessage from "./FlashMessage";

const { $ } = window;

/**
 * React Component that will display the student's submission alongside
 * the answer key for the assignment.
 */
const Comparison = () => {
  const { canvas_assignment } = window;
  const dispatch = useDispatch();
  const {
    submissionContent,
    grade,
    submissionError,
    shouldGrade,
    timeOverride,
  } = useSelector((state) => state.submission);
  const {
    solution,
    answerkeyTedit,
    submissionTedit,
    footnotes,
    medWordsAdded,
    assignment,
    elapsedTime
  } = useSelector((state) => state.setup);
  const [loaded, setLoaded] = useState(false);
  const [gradeSent, setGradeSent] = useState(false);
  const [graded, setGraded] = useState(false);
  const [footnotesDisplayVal, setFootnotesDisplayVal] = useState('none');
  const [footnotesDisplayLeft, setFootnotesDisplayLeft] = useState('100px');
  const [footnotesDisplayTop, setFootnotesDisplayTop] = useState('100px');
  const [footnotesDisplayContent, setFootnotesDisplayContent] = useState('');

  const colors = ['#FFFFFF', '#AC3A3A', '#8F39BE', '#3163CB'];
  const chartData = grade.statistics ? [
    {
      name: 'Correct',
      value: grade.statistics.finalScore,
    },
    {
      name: 'Critical Errors',
      value: grade.statistics.critical,
    },
    {
      name: 'Non Critical Errors',
      value: grade.statistics.nonCritical,
    },
    {
      name: 'Minor Errors',
      value: grade.statistics.minor,
    },
  ] : [];

  const resizeIframeForComparison = () => {
    // Resize iframe to show full comparison
    let heights = $('mm-thin-editor-document').map(function () {
      return $(this).height();
    }).get();

    if (heights.length) {
      heights = [...heights, 300]; // add min height
      const maxHeight = Math.max.apply(null, heights);
      const height = maxHeight + 390; // add height of scoreDisplay
      resizeIFrame(height, assignment.postMessageToken);
    }
  };

  useEffect(() => {
    if (!solution) {
      dispatch(loadSolution(assignment.name || canvas_assignment.name));
    } else if (answerkeyTedit && submissionTedit && !loaded) {
      resizeIframeForComparison();

      // Remove all medical words added to the dictionary for use in the entry module.
      const englishDictionary = submissionTedit.spellCheck().getSpellCheckImplementation();
      for (let i = 0; i < medWordsAdded.length; i++) {
        englishDictionary.remove(medWordsAdded[i]);
      }

      // Grade the submission
      dispatch(scoreSubmission(
        submissionTedit,
        answerkeyTedit,
      ));
      $('.scroll-container').css('overflow-y', 'inherit');
      $('.mm-thin-editor').css('height', '1');
      $(window).click(() => setFootnotesDisplayVal('none'));

      // move submission toolbar to correct location
      $('#toolbar-root').prepend($('#right').find($('mm-thin-editor-toolbar')).css('display', 'flex'));
      answerkeyTedit.getOptions().showToolbar = true;

      answerkeyTedit.getDocument()._audioPromise.then((ac) => {
        ac.event.ready.fire();
        answerkeyTedit.toolbar()._fireConfigChanged();
        dispatch(setupKeyboardShortcuts(answerkeyTedit));
        dispatch(handleAudioLoaded(answerkeyTedit));
        const playContainer = answerkeyTedit.toolbar().findContainer('audio').find('play');
        ac.event.on('play', () => playContainer.setIcon('ai-pause'));
        ac.event.on('pause', () => playContainer.setIcon('ai-play'));
        ac.event.on('fastForward', () => playContainer.setIcon('ai-play'));
      });

      $('.dropdown-toggle').parent().remove();

      setLoaded(true);
    }

    if (grade.submission && !graded) { // -- run once after graded
      resizeIframeForComparison();
      setGraded(true);
    }

    if (!gradeSent && loaded && shouldGrade && grade.submission) {
      dispatch(sendGradeToCanvas('Completed', 'FullyGraded', grade, $('html').clone(), assignment.link, assignment.sessionId));
      setGradeSent(true);
    }

    if (answerkeyTedit) {
      // Remove and readd event handler with correct value
      // of footnotesDisplayVal
      $('cda-sup').unbind();
      $('cda-sup').click((e) => {
        setFootnotesDisplayVal(footnotesDisplayVal === 'flex' ? 'none' : 'flex');
        const boundingRect = e.target.getBoundingClientRect();
        setFootnotesDisplayContent(<div><div style={{ fontWeight: 'bold' }}>Note {e.target.textContent}</div><div>{footnotes.footnotes[e.target.textContent - 1]}</div></div>);
        const footnoteHeight = $('#footnoteWindow').height();
        setFootnotesDisplayLeft(`${boundingRect.left + $(window).scrollLeft() - 300}px`);
        setFootnotesDisplayTop(`${boundingRect.top + $(window).scrollTop() - footnoteHeight - 15}px`);

        e.preventDefault();
        return false;
      });
    }
  }, [
    solution,
    answerkeyTedit,
    submissionTedit,
    loaded,
    grade,
    gradeSent,
    dispatch,
    footnotes,
    medWordsAdded,
    footnotesDisplayVal,
    shouldGrade,
    timeOverride,
    assignment,
  ]);

  // -- HH:MM:SS
  const timeDisplayElapsedTime = (epoch) => {
    const dateObj = new Date(epoch);
    const pad = (number) => (`0${number}`).slice(-2);
    return `${pad(dateObj.getUTCHours())}:${pad(dateObj.getUTCMinutes())}:${pad(dateObj.getUTCSeconds())}`;
  };

  const transcriptionScoreDisplay = () => {
    return (
      <div className="scoreDisplayColumn" style={{ paddingTop: '13px', width: '30%', paddingLeft: '40px' }}>
        <div className='score-metric'>Words: {grade.statistics.words}</div>
        <div className='score-metric'>Time: {timeDisplayElapsedTime(elapsedTime)}</div>
      </div>
    );
  };

  const editScoreDisplay = () => {
    return (
      <div className='scoreDisplayColumn' style={{ width: '18%' }}></div>
    );
  };

  const scoreDisplayText = (name, value) => {
    if (name === 'Correct' || value === 0) {
      return value.toString();
    }
    return `-${value} pts`;
  };

  const errorScoreDisplay = () => {
    return (
      <div style={{ height: '100%' }}>
        <div className="scoreDisplayColumn" style={{ paddingTop: '2%' }}>
          <i className='ai-exclamation-circle ai-4x' style={{ color: 'red', marginLeft: '25px' }}></i>
        </div>
        <div className="scoreDisplayColumn" style={{ width: '75%' }}>
          <h3>
            There was an error submitting your assignment. Please contact your course administrator.
          </h3>
        </div>
      </div>
    );
  };


  return (
    <div className="comparison">
      <div id="entry-data" data-last-saved={Date.now()} data-elapsed-time={elapsedTime}/>
      <FlashMessage/>
      { !graded && <LoadSpinner title="submission"/> }
      { solution
      && <div style={{ height: '100%'}}>
        { grade.statistics.finalScore !== null && grade.statistics.finalScore !== undefined
        && <div className="scoreDisplay">
          {submissionError && errorScoreDisplay()}
          {!submissionError
            && <div className="scoreDisplayColumn" style={{ paddingLeft: '40px' }}>
            <h2>Your Results</h2>
            <div style={{ fontSize: 30 }}>
              {grade.statistics.finalScore}%
            </div>
          </div>}
          {footnotes.assignmentType === 'transcription' && !submissionError && transcriptionScoreDisplay()}
          {footnotes.assignmentType === 'edit' && !submissionError && editScoreDisplay()}
          <div className="scoreDisplayColumn" style={{ width: '18%' }}>
            <div>
            <PieChart width={120} height={120}>
                <Pie data={chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                {
                  chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]}/>
                  ))
                }
                </Pie>
              </PieChart>
            </div>
          </div>
          <div className="scoreDisplayColumn" style={{ paddingTop: '1em', width: '27%' }}>
              {chartData.map((dataEl, i) => (
                <div className='score-metric' style={{ fontSize: '16px' }}>
                  <span className="dot" style={{ backgroundColor: colors[i] }} />
                  {dataEl.name}: {scoreDisplayText(dataEl.name, dataEl.value)}</div>))}
          </div>
        </div>
        }
        <AudioMetaBar audio={answerkeyTedit && answerkeyTedit.getDocument().getAudioControl()}/>
        <div id="toolbar-root"/>
        <div style={{ height: '100%' }}>
          <div id='left'>
            <div className='editorHeader'>
              <span className='sectionTitle'>Your solution</span>
              <span className='sectionTitleButton'>
                <button
                  className='showNotesButton'
                  onClick={() => {
                    const ac = answerkeyTedit.getDocument().getAudioControl();
                    if (ac) {
                      ac.stop();
                    }
                    dispatch(resetSaveState());
                    dispatch(returnToEditSubmission());
                  }}
                >
                Edit Submission
                </button>
              </span>
            </div>
            <ThinEditor
              elName="ed1"
              content={submissionContent}
              teditInstance={SUBMISSION}
              loadWhen={answerkeyTedit}
              useToolbar={false}
              readonly={true}
              preventCopy={true}
              hidden={!loaded}
              spellCheckEnabled={false}
            />
          </div>
          <div id='right'>
          <div id='footnoteWindow' style={{ left: footnotesDisplayLeft, top: footnotesDisplayTop, display: footnotesDisplayVal }}>{footnotesDisplayContent}</div>
          <div className='editorHeader'>
            <span className='sectionTitle'>Answer Key</span>
            <span className='sectionTitleButton'>
              <button
                className='showNotesButton'
                onClick={() => {
                  if (footnotes) {
                    const footnotesHtml = footnotes.footnotes.map((footnote) => <li>{footnote}</li>);
                    dispatch(setModalContent({
                      title: 'Assignment notes',
                      body: <ol>{footnotesHtml}</ol>,
                    }));
                    dispatch(setShowModal());
                  }
                }}
              >
                Show all notes
              </button>
            </span>
          </div>
          <ThinEditor
            elName="ed2"
            preventCopy={true}
            content={solution}
            teditInstance={ANSWERKEY}
            loadWhen={true}
            useToolbar={false}
            audio={`${assignment.name || canvas_assignment.name}`}
            readonly={true}
            hidden={!loaded}
            spellCheckEnabled={false}
          />
        </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Comparison;
