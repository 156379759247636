/*
 * Copyright 2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React from 'react';

/**
 * React component used to display an error message if one
 * of the required resources fails to load.
 */
const ErrorMessage = () => {
  return <h2>There was an error loading assignment. Please contact your course administrator.</h2>;
};

export default ErrorMessage;
