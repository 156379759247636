/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

/* eslint-disable no-nested-ternary */
import * as Diff from 'diff';
import $ from 'jquery';
import stringSimilarity from 'string-similarity';

import medicalDictionary from '../medicalDictionary.json';
import numberDictionary from '../numberDictionary.json';
import articleWords from '../articleWords.json';

import {
  SUBMIT_ENTRY,
  SUBMIT_ENTRY_FAILURE,
  SUBMISSION_GRADED,
  SUBMISSION_UPLOADED,
  SUBMISSION_UPLOAD_FAILURE,
  SET_ASSIGNMENT_NAME,
  ASSIGNMENT_RENAME_FAILURE,
  RESET_SAVE_STATE,
} from './types';

import {SUBMISSION_KEY, DEDUCTION_TYPES, DEDUCTIONS} from '../constants';
import {setFlashMessage} from "./setup";

const textNode = (content = '') => document.createTextNode(content);

const previousMisspellings = {};

const isNumberSynonym = (correctWord, typedWord) => {
  return (typeof typedWord === 'string') && numberDictionary[typedWord] === correctWord;
};

/**
 * Helper determine if word is in medical dictionary.
 */
const isWordInMedicalDictionary = (word) => {
  return (typeof word === 'string') && medicalDictionary[word.toLocaleLowerCase()] === true;
};

/**
 * Helper determine if word is an article word
 */
const isWordInArticleDictionary = (word) => {
  return (typeof word === 'string') && articleWords[word.toLocaleLowerCase()] === true;
};

const deductionFromWord = (word, isMisspelled) => {
  if (isWordInArticleDictionary(word)) {
    return DEDUCTION_TYPES.ARTICLE;
  }

  if (isWordInMedicalDictionary(word)) {
    return isMisspelled ? DEDUCTION_TYPES.MEDICAL_MISSPELLING : DEDUCTION_TYPES.MEDICAL;
  }

  return isMisspelled ? DEDUCTION_TYPES.NON_MEDICAL_MISSPELLING : DEDUCTION_TYPES.NON_MEDICAL;
};

/**
 * Helper for generating the HTML page to be displayed in speed grader.
 * @param {JQuery Obj} htmlSnapshot - jQuery Object representing the html of the page to save.
 */
const generateSubmissionHtml = async (htmlSnapshot) => {
  const generateDocumentHtml = async (doc, documentEl) => {
    const teditParsedValidAttr = 'tedit-parsed-valid';
    const teditParsedTypeAttr = 'tedit-parsed-type';
    const replaceMmSpan = (i, v) => {
      const $v = $(v);
      const styleCode = $v.attr('cda-stylecode');
      let backgroundColor;
      if (styleCode === 'minor') {
        backgroundColor = '#3163CB';
      } else if (styleCode === 'critical') {
        backgroundColor = '#AC3A3A';
      } else if (styleCode === 'non-critical') {
        backgroundColor = '#8F39BE';
      }

      const errorTitle = `${$v.find('.error').text()} - ${$v.find('.error-type').text()}`;
      $v.children().remove();
      $v.replaceWith($('<span></span>')
        .attr('style', `color: ${backgroundColor};`)
        .attr('title', errorTitle)
        .append($v.contents()));
    };
    $(doc).find('cda-section').each((index, val) => {
      const newHtmlContent = $('<div class="section"></div>');
      const newBodyContent = $('<div class="body"></div>');
      const existingBodyContent = $(val).find('cda-text');
      // Replace all problematic elements and attributes in submission body
      existingBodyContent.find('cda-content').each((i, v) => $(v).replaceWith($(v).contents()));
      existingBodyContent.find('cda-paragraph').each((i, v) => $(v).replaceWith($('<p></p>').append($(v).contents())));
      existingBodyContent.find(`[${teditParsedValidAttr}]`).removeAttr(teditParsedValidAttr);
      existingBodyContent.find(`[${teditParsedTypeAttr}]`).removeAttr(teditParsedTypeAttr);
      existingBodyContent.find('mm-span').each(replaceMmSpan);

      const newTitleContent = $(val).find('cda-title');
      newTitleContent.find('mm-span').each(replaceMmSpan);
      newHtmlContent.append(`<h1>${newTitleContent.html()}</h1>`);
      newBodyContent.append(existingBodyContent[0].innerHTML.replace(/cda-/g, ''));
      newHtmlContent.append(newBodyContent);
      documentEl.append(newHtmlContent);
    });

    return documentEl;
  };

  const clinicalDocuments = htmlSnapshot.find('#root').find('cda-clinicaldocument');
  const submissionDoc = await generateDocumentHtml(clinicalDocuments[0], $('<div id="left" style="float: left; margin-left: 5%; width: 40%"></div>'));
  const answerDoc = await generateDocumentHtml(clinicalDocuments[1], $('<div id="right" style="float: right; margin-right: 5%; width: 40%"></div>'));
  const returnEl = $('<div id="submission"></div>');
  const submissionContainer = $('<div id="submissionContainer"></div>');
  const scoreDisplay = htmlSnapshot.find('div.scoreDisplay');
  const entryData = htmlSnapshot.find('#entry-data');
  scoreDisplay.css('display', 'flex');
  scoreDisplay.css('border', '1');
  scoreDisplay.css('border-style', 'solid');
  $(scoreDisplay.find('div.scoreDisplayColumn')[2]).remove(); // removing graph col, which can't be displayed.
  scoreDisplay.find('div.scoreDisplayColumn').css('width', '33%');
  scoreDisplay.find('div.scoreDisplayColumn').css('padding-top', '0');
  returnEl.append(scoreDisplay);
  returnEl.append(entryData);
  submissionContainer.append(submissionDoc);
  submissionContainer.append(answerDoc);
  returnEl.append(submissionContainer);

  return returnEl;
};

const getTextNodesWithCombine = (rootNode) => {
  if (rootNode.nodeName === 'CDA-SUP') {
    return [];
  }

  const nodes = [];
  for (let i = 0; i < rootNode.childNodes.length; i++) {
    const current = rootNode.childNodes[i];
    if (current.nodeType === Node.TEXT_NODE) {
      nodes.push(current);
    } else {
      const children = getTextNodesWithCombine(current);
      children.forEach((node) => nodes.push(node));
    }
  }

  for (let i = 0; i < nodes.length; i++) {
    // -- if this node ends with a word character and the next node begins with a word character (not separated by punctuation or whitespace)
    if (nodes[i + 1] && /\w$/.test(nodes[i].textContent) && /^\w/.test(nodes[i + 1].textContent)) {
      // =======  if the sequential nodes are sibling text nodes and not from different paragraphs, ordered lists, or bullet points
      if (nodes[i].nextSibling === nodes[i + 1] ||                       // `#text#text`
          nodes[i].nextSibling === nodes[i + 1].parentNode ||            // `#text<cda-content>#text</cda-content>`
          nodes[i].parentNode.nextSibling === nodes[i + 1] ||            // `<cda-content>#text</cda-content>#text`
          nodes[i].parentNode.nextSibling === nodes[i + 1].parentNode) { // `<cda-content>#text</cda-content><cda-content>#text</cda-content>`
        nodes[i + 1].textContent = nodes[i].textContent + nodes[i + 1].textContent;
        nodes[i].textContent = '';
      }
    }
  }

  return nodes;
};

// --------  Enabling this will show feedback and deduct points for incorrect section titles
// const getTitleTextNodes = (rootNode) => {
//   const titleNodes = [];
//   for (let i = 0; i < rootNode.childNodes.length; i++) {
//     const current = rootNode.childNodes[i];
//
//     let nodes = [];
//     if (current.nodeName === 'CDA-TITLE') {
//       nodes = getTextNodesWithCombine(current);
//       if (!nodes.length) {
//         const newNode = textNode();
//         current.appendChild(newNode);
//         nodes = [newNode];
//       }
//     } else if (current.hasChildNodes()) {
//       nodes = getTitleTextNodes(current);
//     }
//
//     nodes.forEach((node) => {
//       titleNodes.push(node);
//     });
//   }
//   return titleNodes;
// };

const gatherTextNodes = (rootNode) => {
  const nodes = [];
  for (let i = 0; i < rootNode.childNodes.length; i++) {
    const current = rootNode.childNodes[i];

    if (current.nodeName === 'CDA-TITLE' || current.nodeName === 'CDA-PARAGRAPH' || current.nodeName === 'CDA-TEXT') {
      nodes.push(...getTextNodesWithCombine(current));
    } else if (current.hasChildNodes()) {
      nodes.push(...gatherTextNodes(current));
    }
  }
  return nodes;
};

const getWordNodes = (rootNode) => {
  return gatherTextNodes(rootNode).filter((node) => /\w/.test(node.textContent));
};

/**
 * Helper for determining whether we should get the text of the given node.
 * @param {Node} node - The node to analyze.
 */
const shouldGetText = (node) => {
  if (node.nodeType !== Node.ELEMENT_NODE || !node.hasChildNodes()) {
    return true;
  }

  let ignoreChildNodes = false;

  for (let i = 0; i < node.childNodes.length; i++) {
    const element = node.childNodes[i];
    // we can simply retrieve text for text nodes, or element nodes that start with mm-, or element
    // nodes that are named "cda-content". We want to skip 'cda-sup' elements because they are used
    // for footnotes.
    ignoreChildNodes = (element.nodeType === Node.TEXT_NODE
      && element.textContent.trim().length > 0)
      || (element.nodeType === Node.ELEMENT_NODE && (element.nodeName.startsWith('MM-')
      || element.nodeName === 'CDA-CONTENT'));
  }

  return ignoreChildNodes;
};

const normalizeWords = (words) => {
  return words.split(' ').map((word) => {
    word = word.trim();
    if (word === '%') {
      return word;
    }

    const percentMatch = word.match(/^(\d+)(%)$/);
    if (percentMatch) {
      return [percentMatch[1], percentMatch[2]]; // -- "100%" into [ "100", "%" ]
    }

    return word.replace(/^\W*|\W*$/g, '').toLowerCase();
  }).flat()
    .filter((word) => word)
    .join(' ');
};

/**
 * Helper function for pulling out the text children of a given node.
 * @param {Node} element - The node to pull text node children out of.
 */
const getTextContentFromNode = (element) => {
  const linesOfText = [];
  if (element.hasChildNodes()) {
    for (let i = 0; i < element.childNodes.length; i++) {
      const elem = element.childNodes[i];
      if (shouldGetText(elem)) {
        linesOfText.push(elem.textContent);
      } else {
        const childLines = getTextContentFromNode(elem);
        for (let j = 0; j < childLines.length; j++) {
          linesOfText.push(childLines[j]);
        }
      }
    }
  } else {
    linesOfText.push(element.textContent);
  }
  return linesOfText;
};

const getNormalizedTextContentFromNode = (element) => {
  return getTextContentFromNode(element).map(normalizeWords).join(' ');
};

export const removeSpellingErrors = (content) => {
  return content.replace(/<\/?mm:spell-error>/g, '');
};

export const removeSpellingErrorNodes = (rootNode) => {
  // first get rid of spelling errors and normalize text nodes
  let shouldNormalize;
  for (let i = 0; i < rootNode.childNodes.length; i++) {
    const current = rootNode.childNodes[i];
    if (current.hasChildNodes()) {
      removeSpellingErrorNodes(current);
      // -- this may never be true because 'spellcheckEnabled' is set to false
    } else if (current.nodeName === 'MM-SPELL-ERROR') {
      rootNode.replaceChild(textNode(current.textContent), current);
      shouldNormalize = true;
    }
  }

  // -- normalization is only necessary if we are replacing spelling errors.
  if (shouldNormalize) {
    rootNode.normalize();
  }
};

const isSimilar = (correctString, typedString) => {
  const { length } = correctString;
  let likeness = 0.7;
  if (length < 9 && length >= 5) {
    likeness = 0.5;
  } else if (length < 5) {
    likeness = 0.3;
  }

  return correctString === typedString
    || stringSimilarity.compareTwoStrings(correctString, typedString) >= likeness;
};

const getNormalizedTextContentFromNodes = (nodes) => {
  return nodes.map((node) => {
    return getNormalizedTextContentFromNode(node);
  }).join(' ');
};

export const getDiffsFromText = (submissionText, answerText) => {
  // -- capture diffs from nodes, remove any nodes that are whitespace or do not contain word characters.
  const diffs = Diff.diffWords(answerText, submissionText, { ignoreCase: true })
    .map((part) => ({
      value: part.value.trim(),
      added: part.added,
      removed: part.removed,
      same: !part.added && !part.removed,
      words: part.value.split(/\s|&nbsp;/g).filter((w) => w),
    }))
    .filter((part) => /\w|^%$/.test(part.value));

  const flatDiffs = []; // -- this flattens out any grouped 'added' or 'removed'. This makes figuring out deduction easier.
  diffs.forEach((diff) => {
    if (diff.same) {
      flatDiffs.push(diff);
      return;
    }
    diff.words.forEach((word) => {
      flatDiffs.push({
        ...diff,
        value: word,
        words: [word],
      });
    });
  });

  // -- Capture expected word to make things easier later.
  for (let i = 0; i < flatDiffs.length; i++) {
    if (flatDiffs[i].removed && flatDiffs[i + 1] && flatDiffs[i + 1].added) {
      flatDiffs[i].typedWord = flatDiffs[i + 1].words[0];
      flatDiffs[i + 1].correctWord = flatDiffs[i].words[flatDiffs[i].words.length - 1];
    }
  }

  return flatDiffs.map((diff) => { // -- Separated for readability
    if (diff.removed || diff.added) {
      diff.extraWord = (diff.removed && !diff.typedWord) || (diff.added && !diff.correctWord);

      const word = diff.correctWord || diff.value;

      if (!diff.extraWord) {
        const typedWord = diff.typedWord || diff.value;
        diff.isSynonym = isNumberSynonym(word, typedWord);
        diff.isMisspelled = word !== typedWord && isSimilar(word, typedWord);
      }

      diff.deduction = !diff.isSynonym && deductionFromWord(word, diff.isMisspelled);
    }

    return diff;
  });
};

const newTextNodeWithAttributes = (textContent, deduction) => {
  const deductionProp = DEDUCTIONS[deduction];
  const newNode = document.createElement('mm-span');
  newNode.className = 'tooltip-error word';
  newNode.textContent = textContent;
  newNode.setAttribute('cda-stylecode', deductionProp.style);

  const dot = `<span class="dot ${deductionProp.style}"></span>`;
  const content = `
<div class="tooltip-content word">
  ${dot} 
  <div class="tooltip-text">
    <div class="error-type">${deductionProp.title}</div>
    <div class="error">${deduction} ${deductionProp.pointsDisplay}</div>
  </div>
</div>`;

  $(newNode).append(content);
  return newNode;
};

const nodeTransmuter = function* (nodes) {
  if (!nodes.length) {
    return;
  }

  let nodeIndex = 0;
  let wordIndex = 0;
  let nodeText = nodes[nodeIndex].textContent.toLowerCase();
  let newNode;
  let diff;
  let foundIndex = 0;
  while (nodes[nodeIndex]) {
    if (foundIndex >= 0) {
      diff = yield newNode;
    }

    foundIndex = nodeText.indexOf(diff.word, wordIndex);
    if (foundIndex < 0) {
      nodeIndex++;
      if (!nodes[nodeIndex]) {
        return;
      }
      nodeText = nodes[nodeIndex].textContent.toLowerCase();
      wordIndex = 0;
    } else {
      wordIndex = foundIndex + diff.word.length;

      if (diff.deduction) {
        const node = nodes[nodeIndex];
        const start = node.textContent.substring(0, foundIndex);
        const originalWord = node.textContent.substring(foundIndex, wordIndex);
        const end = node.textContent.substring(wordIndex, node.textContent.length);

        newNode = newTextNodeWithAttributes(originalWord, diff.deduction);

        node.replaceWith(start, newNode, end);
        nodes[nodeIndex] = newNode.nextSibling;
        nodeText = nodes[nodeIndex].textContent.toLowerCase();
        wordIndex = 0;
      }
    }
  }
};

export const addOnHover = (nodeA, nodeB) => {
  $(nodeA).hover(
    async () => {
      $(nodeA).addClass('error-hover');
      if (nodeB) {
        $(nodeB).addClass('error-hover');
      }
    }, () => {
      $(nodeA).removeClass('error-hover');
      if (nodeB) {
        $(nodeB).removeClass('error-hover');
      }
    },
  );

  if (!nodeB) {
    return;
  }

  $(nodeB).hover(
    async () => {
      $(nodeA).addClass('error-hover');
      $(nodeB).addClass('error-hover');
    }, () => {
      $(nodeA).removeClass('error-hover');
      $(nodeB).removeClass('error-hover');
    },
  );
};

export const replaceTextOnDeduction = (submissionNodes, answerNodes, onDeduction, onWord) => {
  const submissionText = getNormalizedTextContentFromNodes(submissionNodes);
  const answerText = getNormalizedTextContentFromNodes(answerNodes);

  const diffs = getDiffsFromText(submissionText, answerText);
  const submissionTransmuter = nodeTransmuter(submissionNodes);
  const answerTransmuter = nodeTransmuter(answerNodes);

  submissionTransmuter.next();
  answerTransmuter.next();

  // -- these two are to attach 'hover' to misspelled words.
  let previousNode;

  diffs.forEach(({ words, same, added, removed, isSynonym, deduction, extraWord, correctWord }) => {
    if (!removed) { // -- count only from submission
      onWord(words.length);
    }

    words.forEach((word) => {
      if (same) {
        answerTransmuter.next({ word });
        submissionTransmuter.next({ word });
        return; // -- nothing wrong return before deduction
      }

      let newNode;
      if (removed) { // -- diff word is missing('removed') from answer, could also be a misspelling('answerWordMisspelled')
        newNode = answerTransmuter.next({word, deduction}).value;
      } else { // -- diff word is extra('added') on the submission, could also be the typed word of a misspelled word and have '.correctWord'
        newNode = submissionTransmuter.next({word, deduction}).value;
      }

      if (isSynonym) {
        return;
      }

      if (extraWord) {
        addOnHover(newNode);
      } else if (added) {
        addOnHover(newNode, previousNode);
      }

      previousNode = newNode;

      // -- This prevents a duplicate 'deduction' for words that are misspelled. There is another 'diff' for the 'correctWord'.
      if (added && !extraWord) {
        return;
      }

      // -- 'previousMisspellings' is a global variable
      if (correctWord) {
        const misspellingLookup = `${word}_${correctWord}`;

        if (previousMisspellings[misspellingLookup]) {
          return;
        }

        previousMisspellings[misspellingLookup] = true;
      }

      onDeduction(deduction);
    });
  });
}

const styleTitleNode = (node, deductionTitle) => {
  const deduction = DEDUCTIONS[deductionTitle];

  node.setAttribute('cda-stylecode', deduction.style);
  node.className = 'tooltip-error title';

  const dot = `<span class="dot ${deduction.style}"></span>`;
  const content = `
<div class="tooltip-content title">
  ${dot} 
  <div class="tooltip-text">
    <div>${deduction.title}</div>
    <div class="error">${deductionTitle} ${deduction.pointsDisplay}</div>
  </div>
</div>`;

  $(node).append(content);
  return node;
};

export const gradeSectionTitles = (submissionTitleNodes, answerTitleNodes, onDeduction) => {
  const submissionTitles = submissionTitleNodes.map(getNormalizedTextContentFromNode);
  const answerTitles = answerTitleNodes.map(getNormalizedTextContentFromNode);

  const options = {
    comparator: isSimilar,
    ignoreCase: true,
  };
  const diffs = Diff.diffArrays(answerTitles, submissionTitles, options);

  const flatDiffs = []; // -- this flattens out any grouped 'added' or 'removed'. This makes figuring out deduction easier.
  diffs.forEach((diff) => {
    diff.value.forEach((title) => {
      flatDiffs.push({
        ...diff,
        value: [title],
        count: 1,
      });
    });
  });

  for (let i = 0; i < flatDiffs.length; i++) {
    if (flatDiffs[i].removed && !(flatDiffs[i + 1] && flatDiffs[i + 1].added)) {
      flatDiffs[i].extra = true;
    } else if (flatDiffs[i].added && !(flatDiffs[i - 1] && flatDiffs[i - 1].removed)) {
      flatDiffs[i].extra = true;
    }
    if (flatDiffs[i].added || flatDiffs[i].removed) {
      flatDiffs[i].deduction = !flatDiffs[i].extra ? DEDUCTION_TYPES.SECTION_MISLABELED
        : flatDiffs[i].removed ? DEDUCTION_TYPES.REMOVED_SECTION : DEDUCTION_TYPES.ADDED_SECTION;
    } else {
      flatDiffs[i].same = true;
    }
  }

  let submissionIndex = 0;
  let answerIndex = 0;
  flatDiffs.forEach(({ same, extra, added, removed, deduction }) => {
    // this prevents a duplicate if the 'diff' was considered a 'change'
    if (removed && !extra) {
      return;
    }

    let submissionTitleNode;
    let answerTitleNode;
    if (same || !extra) {
      submissionTitleNode = submissionTitleNodes[submissionIndex++];
      answerTitleNode = answerTitleNodes[answerIndex++];
    } else if (added) {
      submissionTitleNode = submissionTitleNodes[submissionIndex++];
    } else if (removed) {
      answerTitleNode = answerTitleNodes[answerIndex++];
    }

    if (deduction) {
      onDeduction(deduction);
      if (submissionTitleNode) {
        styleTitleNode(submissionTitleNode.parentNode, deduction);
      }
      if (answerTitleNode) {
        styleTitleNode(answerTitleNode.parentNode, deduction);
      }

      if (submissionTitleNode && answerTitleNode) {
        addOnHover(submissionTitleNode.parentNode, answerTitleNode.parentNode);
      } else {
        const node = submissionTitleNode ? submissionTitleNode.parentNode : answerTitleNode.parentNode;
        addOnHover(node);
      }
    }
  });
};

/**
 * Method used to grade all of the sections in a submission.
 * @param {ThinEditor} entryTedit - The ThinEditor instance used to display
 *  the student's submission.
 * @param {ThinEditor} answerTedit - The ThinEditor instance used to display
 *  the answer key.
 */
export const grade = async (entryTedit, answerTedit) => {
  const submission = await entryTedit.getDocument().body();
  const answer = await answerTedit.getDocument().body();

  removeSpellingErrorNodes(submission);
  removeSpellingErrorNodes(answer);

  const submissionTextNodes = getWordNodes(submission);
  const answerTextNodes = getWordNodes(answer);

  const deductions = {
    points: 0,
    critical: 0,
    nonCritical: 0,
    minor: 0,
  };

  let words = 0;
  const onWord = (count) => {
    words += count;
  };

  const onDeduction = (title) => {
    const { points } = DEDUCTIONS[title];
    Object.keys(points).forEach((type) => {
      deductions[type] += points[type];
      deductions.points += points[type];
    });
  };

  replaceTextOnDeduction(submissionTextNodes, answerTextNodes, onDeduction, onWord);

  // --------  Enabling this will show feedback and deduct points for incorrect section titles
  // const submissionTitleNodes = getTitleTextNodes(submission);
  // const answerTitleNodes = getTitleTextNodes(answer);
  // gradeSectionTitles(submissionTitleNodes, answerTitleNodes, onDeduction);

  return { deductions, words };
};

/**
 * Action used to submit a student's work. Dispatches the action that moves
 *  the UI to the answer key page.
 * @param {ThinEditor} entryTedit - The ThinEditor instance in which the student
 *  entered their work.
 */
export const submitEntry = (entryTedit) => {
  return async (dispatch) => {
    if (entryTedit) {
      try {
        // Pause audio
        const audioControl = entryTedit.getDocument().getAudioControl();
        if (audioControl) {
          audioControl.pause();
        }

        // Export content
        let content = await entryTedit.export();
        content = content.replace(/<mm:spell-error>/g, '').replace(/<\/mm:spell-error>/g, '');
        dispatch({ type: SUBMIT_ENTRY, payload: { content, shouldGrade: true } });
      } catch (e) {
        dispatch({ type: SUBMIT_ENTRY_FAILURE });
      }
    } else {
      dispatch({ type: SUBMIT_ENTRY_FAILURE });
    }
  };
};

/**
 * Action to grade both titles and bodies of all sections.
 * @param {ThinEditor} entryTedit - The ThinEditor instance used to display
 *  the student's submission.
 * @param {ThinEditor} answerTedit - The ThinEditor instance used to display
 *  the answer key.
 * @param {Date} startime - The start time of the assignment, used to calculate
 *  total assignment time.
 */
export const scoreSubmission = (
  entryTedit,
  answerTedit,
) => {
  return async (dispatch) => {
    const submission = await entryTedit.export();
    const { deductions, words } = await grade(entryTedit, answerTedit);

    // Calculate the final score
    let finalScore = 100 - deductions.points;
    finalScore = finalScore < 0 ? 0 : finalScore;
    const statistics = {
      ...deductions,
      words,
      finalScore,
    };
    const fullSubmission = {
      submission,
      statistics,
    };
    dispatch({ type: SUBMISSION_GRADED, payload: fullSubmission });
  };
};

/**
 * Action for submitting grades back to canvas.
 * @param {String} activityProgress - String indicator of the status of the
 *  activity progress (i.e. finished, in progress)
 * @param {String} gradingProgress - String indicator of the status of the
 *  grading of the activity (i.e. finished, in progress)
 * @param {Object} gradeContent - Object representing the submission and the
 *  statistics on how the student performed on the assignment.
 * @param {JQuery Object} htmlSnapshot - JQuery object representing the html
 *  of the current page.
 * @param {String} link - submission link of assignment
 */
export const sendGradeToCanvas = (
  activityProgress,
  gradingProgress,
  gradeContent,
  htmlSnapshot,
  link,
  sessionId,
) => {
  return async (dispatch) => {
    // Work with the html to make it more suitable for viewing in speedgrader.
    const submissionEl = await generateSubmissionHtml(htmlSnapshot);
    const encodedSubEl = $('<div id="encodedSubmission" style="display: none; "></div>');
    const base64Encoded = Buffer.from(gradeContent.submission).toString('base64');
    encodedSubEl.append(base64Encoded);
    submissionEl.append(encodedSubEl);

    // send score back to canvas
    const score = {
      scoreGiven: gradeContent.statistics.finalScore,
      scoreMaximum: 100,
      timestamp: new Date().toISOString(),
      activityProgress,
      gradingProgress,
      [SUBMISSION_KEY]: {
        new_submission: true,
        submission_type: 'online_text_entry',
        submission_data: submissionEl[0].outerHTML,
      },
    };

    try {
      const submitResp = await fetch(
        '/gradePassback',
        {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            score,
            link,
            sessionId,
          }),
        },
      );
      const submitJson = await submitResp.json();
      if (submitResp.ok) {
        dispatch({ type: SUBMISSION_UPLOADED, payload: submitJson });
      } else {
        dispatch({ type: SUBMISSION_UPLOAD_FAILURE, payload: submitJson });
      }
    } catch (e) {
      dispatch({ type: SUBMISSION_UPLOAD_FAILURE, payload: e });
    }
  };
};

/**
 *  saving template
 * @param {Object} content - Object representing the submission and the
 * @param {String} name - name of the assignment
 */
export const saveTemplate = (
  content,
  name,
) => {
  return async (dispatch) => {

    content = removeSpellingErrors(content);

    const response = await fetch(
      '/template',
      {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, content }),
      },
    );

    if (response.ok) {
      dispatch(setFlashMessage('Successfully Saved'));
    } else {
      dispatch(setFlashMessage('Saved Failed'));
    }
  };
};


/**
 *  saving solution
 * @param {Object} content - Object representing the submission and the
 * @param {String} name - name of the assignment
 */
export const saveSolution = (
  content,
  name,
) => {
  return async (dispatch) => {
    content = removeSpellingErrors(content);
    const response = await fetch(
      '/solution',
      {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, content }),
      },
    );

    if (response.ok) {
      dispatch(setFlashMessage('Successfully Saved'));
    } else {
      window.alert('solution was not saved');
      dispatch(setFlashMessage('Save Failed'));
    }
  };
};


/**
 *  saving template
 * @param {String} newName - String representing the new name of the assignment
 * @param {String} name - String representing the name of the assignment
 * @param {String} assignmentId - canvas id of the assignment
 * @param {String} courseId - canvas course id of the assignment
 */
export const renameAssignment = (
  newName,
  name,
  assignmentId,
  courseId,
) => {
  return async (dispatch) => {
    const response = await fetch(
      '/rename',
      {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newName,
          name,
          assignmentId,
          courseId,
        }),
      },
    );

    const submitJson = await response.json();
    if (response.ok) {
      dispatch({ type: SET_ASSIGNMENT_NAME, payload: newName });
      window.alert('Please refresh the page before continuing');
    } else {
      window.alert(submitJson['Message']);
      dispatch({ type: ASSIGNMENT_RENAME_FAILURE, payload: submitJson });
    }
  };
};

/**
 * Resets the save state to hide the save messgage.
 */
export const resetSaveState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SAVE_STATE });
  };
};
