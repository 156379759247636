import React from 'react';

const GradingModal = () => {
  return (
    <div>
      <h4>Critical Errors (3.0 Error Weight)</h4>
      <ul>
        <li>
          Wrong or omitted medical term
        </li>
        <li>
          Extra medical word
        </li>
      </ul>
      <h4>Noncritical Errors: (1.0 Error Weight)</h4>
      <ul>
        <li>Wrong or omitted english word</li>
        <li>Extra English word</li>
        <li>Misspelled medical word</li>
      </ul>
      <h4>Minor Errors: (.25 Error Weight)</h4>
      <ul>
        <li>Misspelled English word</li>
        <li>Article word</li>
      </ul>
    </div>
  );
};

export default GradingModal;
