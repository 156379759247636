import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setModalContent, setShowModal} from "../actions/setup";

const KeyboardShortcuts = () => {
  const {
    entryTedit,
    submissionTedit,
  } = useSelector((state) => state.setup);

  const dispatch = useDispatch();

  const shortcuts = {
    structure: {
      toggleSectionTitle: true,
      indent: true,
      outdent: true,
    },
    audio: {
      audioRewind: true,
      audioRewindToStart: true,
      audioPlayPause: true,
      audioFastForward: true,
      audioResetRate: true,
      audioIncreaseRate: true,
      audioDecreaseRate: true,
      attachCursor: true,
      detachCursor: true,
      seekAudioToCursor: true,
    },
  };

  const generateKeyboardShortcutsModalContent = () => {
    const structureList = [];
    const audioList = [];
    const generateListEntry = (key) => {
      const prettyKeys = key.getPrettyKeys().map((prettyKey) => {
        // Setting up different shortcuts for keys that have changed
        if (key._id === 'audioDecreaseRate') {
          prettyKey = 'ctrl + alt + d';
        }
        return (
          <span className='cfp-hotkeys-key'>{prettyKey}</span>
        );
      });

      let description = key.description;
      if (key._id === 'indent') {
        description = description.replace(/^Autocomplete predicted text \/ /, '');
      }
      return (
        <div style={{ marginBottom: '10px', float: 'left', width: '250px' }}>
          <span className='cfp-hotkeys-keys'>{prettyKeys}</span>
          <span className='cfp-hotkeys-text'>{description}</span>
        </div>
      );
    };

    (entryTedit || submissionTedit).hotkeys().getAllList()
      .filter((key) => key.description)
      .forEach((key) => {
        if (shortcuts.structure[key._id]) {
          structureList.push(generateListEntry(key));
        } else if (shortcuts.audio[key._id]) {
          audioList.push(generateListEntry(key));
        }
      });
    dispatch(setModalContent({
      title: 'Keyboard Shortcuts',
      body: (
        <div>
          <div style={{ float: 'left', width: '50%' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Structure</div>
            {structureList}
          </div>
          <div style={{ float: 'right', width: '50%' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Audio</div>
            {audioList}
          </div>
        </div>
      ),
    }));
    dispatch(setShowModal());
  };

  return (
    <a onClick={generateKeyboardShortcutsModalContent}>
      Keyboard Shortcuts
    </a>
  );
};

export default KeyboardShortcuts;
