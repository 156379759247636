/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import { combineReducers } from 'redux';
import navigation from './navigation';
import submission from './submission';
import setup from './setup';

export default combineReducers({
  navigation,
  submission,
  setup,
});
