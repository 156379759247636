/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  attachAudio,
  loadTemplateContent,
  setTedit,
  setTeditFailure,
} from '../actions/setup';

const { $ } = window;

/**
 * React component wrapping ThinEditor.
 * @param {Array} props - The properties passed to the ThinEditor component.
 */
const ThinEditor = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function test() {
      if ($(`#${props.elName}`) && props.loadWhen) {
        $(`#${props.elName}`).thinEditor({
          showToolbar: props.useToolbar,
          undoInterval: 500, // ms
          spellCheckEnabled: props.spellCheckEnabled,
          spellCheckOnTheFly: props.spellCheckEnabled,
          useInternalClipboardOnly: true,
          autoHeal: false,
          autoDocumentValidation: false,
        }, async (api) => {
          if (api.import) {
            try {
              if (props.content !== null && props.content !== '') {
                await loadTemplateContent(api, props.content);

                dispatch(setTedit(api, props.teditInstance));
                api.getDocument().setContentEditable(!props.readonly);
                // remove save button
                const saveButton = $('.ai-save');
                if (saveButton.length > 0) {
                  saveButton.parent().remove();
                }
                if (props.audio) {
                  attachAudio(api, props.audio);
                }
                if (props.preventCopy) {
                  $(`#${props.elName}`).find('cda-clinicaldocument').on('copy paste cut', (e) => {
                    if (e.preventDefault) {
                      e.preventDefault();
                    }

                    if (e.stopImmediatePropagation) {
                      e.stopImmediatePropagation();
                    }
                    return false;
                  });

                  $(`#${props.elName} .btn-toolbar .btn-group:nth-child(3)`).remove(); // -- this is a hackish way to remove the 'copy' 'paste' 'cut' buttons from the compiled source.
                }
              } else {
                dispatch(setTedit(api, props.teditInstance));
              }
            } catch (error) {
              console.log(error);
              dispatch(setTeditFailure(error));
            }
          }
        }, (err) => {
          console.log(err);
          dispatch(setTeditFailure(err));
        });
      }
    }
    test();
  });

  return (
    <div id={props.elName} hidden={props.hidden}></div>
  );
};

export default ThinEditor;
