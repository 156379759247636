import React from 'react';

import { LOCAL_STORAGE_STEP_BACK } from '../constants';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || 0,
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

const AudioStepBack = () => {
  const [value, setValue] = useStateWithLocalStorage(
    LOCAL_STORAGE_STEP_BACK,
  );

  const onChange = (event) => setValue(event.target.value);

  return (
    <div className="title">Auto-backstep
      <select value={value} onChange={onChange} style={{marginLeft: '10px'}}>
        <option value="0">0 Seconds</option>
        <option value="1">1 Seconds</option>
        <option value="2">2 Seconds</option>
        <option value="3">3 Seconds</option>
        <option value="4">4 Seconds</option>
      </select>
    </div>
  );
};

export default AudioStepBack;
