/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import {
  SUBMIT_ENTRY,
  EDIT_ENTRY, SHOW_BLUR_NOTICE,
} from '../actions/types';

import { PAGE_NAMES } from '../constants';

export const INITIAL_STATE = {
  currentPage: PAGE_NAMES.ENTRY,
  showBlurNotice: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_ENTRY:
      return { ...state, currentPage: PAGE_NAMES.COMPARISON };
    case EDIT_ENTRY:
      return { ...state, currentPage: PAGE_NAMES.ENTRY };
    case SHOW_BLUR_NOTICE:
      const showBlurNotice = action.payload && state.currentPage !== PAGE_NAMES.COMPARISON;
      return { ...state, showBlurNotice };
    default:
      return { ...state };
  }
};
