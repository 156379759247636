/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

/**
 * Constants used throughout the application.
 */
export const PAGE_NAMES = {
  ENTRY: 'entry',
  COMPARISON: 'comparison',
};

export const ENTRY = 'entry';
export const SUBMISSION = 'submission';
export const ANSWERKEY = 'answerkey';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const REACT_APP_SOLUTION_SALT = process.env.REACT_APP_SOLUTION_SALT || "4A1D5B10-7AD9-11EB-B229-B329FC2CA371";

export const SUBMISSION_KEY = 'https://canvas.instructure.com/lti/submission';

export const DEDUCTION_TYPES = {
  ADDED_SECTION: 'Section Added',
  REMOVED_SECTION: 'Section Removed',
  SECTION_MISLABELED: 'Section Mislabeled',
  MEDICAL: 'Medical Word Incorrect',
  NON_MEDICAL: 'Non Medical Word Incorrect',
  MEDICAL_MISSPELLING: 'Medical Word Misspelled',
  NON_MEDICAL_MISSPELLING: 'Non Medical Word Misspelled',
  ARTICLE: 'Article Word',
};

export const LOCAL_STORAGE_STEP_BACK = 'MTE_AUDIO_STEP_BACK_AMOUNT';

export const FLASH_MESSAGE_DURATION = 2000;

const critical =    { title: 'Critical Error',     style: 'critical',      points: {critical:    3}, pointsDisplay: '(-3pts)',    color: '#AC3A3A' };
const nonCritical = { title: 'Non Critical Error', style: 'non-critical',  points: {nonCritical: 1}, pointsDisplay: '(-1pts)',    color: '#8F39BE' };
const minor =       { title: 'Minor Error',        style: 'minor',         points: {minor:    0.25}, pointsDisplay: '(-0.25pts)', color: '#3163CB' };

const nonCriticalSection = { ...nonCritical, style: 'non-critical-section' };

export const DEDUCTIONS = {
  [DEDUCTION_TYPES.ADDED_SECTION]: nonCriticalSection,
  [DEDUCTION_TYPES.REMOVED_SECTION]: nonCriticalSection,
  [DEDUCTION_TYPES.SECTION_MISLABELED]: nonCriticalSection,
  [DEDUCTION_TYPES.MEDICAL]: critical,
  [DEDUCTION_TYPES.NON_MEDICAL]: nonCritical,
  [DEDUCTION_TYPES.MEDICAL_MISSPELLING]: nonCritical,
  [DEDUCTION_TYPES.NON_MEDICAL_MISSPELLING]: minor,
  [DEDUCTION_TYPES.ARTICLE]: minor,
}
