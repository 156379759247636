import React from 'react';
import KeyboardShortcuts from "./KeyboardShortcuts";
import AudioStepBack from "./AudioStepBack";
import {useSelector} from "react-redux";

function secondsToTime(e){
  const minutes = Math.floor(e % 3600 / 60).toString();
  const seconds = Math.floor(e % 60).toString().padStart(2,'0');

  return `${minutes}:${seconds}`;
}

const AudioMetaBar = (props) => {
  const {
    currentAudioTime,
    currentAudioRate,
    audioDuration,
  } = useSelector((state) => state.setup);

  const toFixedNumber = (float) => {
    return parseInt(float.toFixed(), 10);
  };

  const onSliderChange = (e) => {
    const progress = toFixedNumber((e.target.value / 100) * audioDuration);
    props.audio.setTime(progress);
  };

  return (
    <ul className="audio-meta-bar">
      <li>
        <KeyboardShortcuts/>
      </li>
      <li className="audio-progress">
        <span className="title">Audio Playback</span>
        <div className="value">
          <div className="accent progress-time">{secondsToTime(currentAudioTime)}</div>
          <input disabled={!props.audio} type="range" value={toFixedNumber(((currentAudioTime / audioDuration) * 100) || 0)} onChange={onSliderChange} />
          <div className="duration">{secondsToTime(audioDuration)}</div>
        </div>
      </li>
      <li>
        <span className="title">Speed:</span>
        <span className="value">
          <span className="accent">{(currentAudioRate * 100).toFixed(0)}%</span>
        </span>

      </li>
      <li>
        <AudioStepBack/>
      </li>
    </ul>
  );
};

export default AudioMetaBar;
