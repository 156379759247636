/*
 * Copyright 2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { useState, useEffect } from 'react';

/**
 * React component representing the modal that is used to display various pieces of information in the application. 
 */
const Modal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [display, setDisplay] = useState('none');
  useEffect(() => {
    if (props.showModal !== showModal) {
      setShowModal(props.showModal);
      setDisplay(props.showModal ? 'inline' : 'none');
    }
  }, [props.showModal, showModal]);

  return (
    <div style={{ display }}>
      <div className="modal-background"/>
      <div className="modal-box">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.cancelFn()}
                style={{ height: '30px' }}
              ></button>
              <div
                style={{ height: '100%', fontWeight: 'bold', marginTop: '5px' }}
              >{props.title}</div>
            </div>
            <div className="modal-body">
              {props.bodyContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
