/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

/**
 * This file represents the redux action types that are used
 * through out the application.
 */

export const SUBMIT_ENTRY = 'submit_entry';
export const SUBMIT_ENTRY_FAILURE = 'submit_entry_failure';
export const EDIT_ENTRY = 'edit_entry';

export const TEDIT_ADDED = 'tedit_added';

export const SUBMISSION_GRADED = 'submission_graded';

export const TEMPLATE_LOADED = 'template_loaded';
export const TEMPLATE_LOAD_FAILURE = 'template_load_failure';

export const SOLUTION_LOADED = 'solution_loaded';
export const SOLUTION_LOAD_FAILURE = 'solution_load_failure';

export const FOOTNOTES_LOADED = 'footnotes_loaded';
export const FOOTNOTES_LOAD_FAILURE = 'footnotes_load_failure';
export const FOOTNOTES_SAVE_FAILURE = 'footnotes_save_failure';
export const FOOTNOTES_EXTENDED = 'footnotes_extended';
export const FOOTNOTES_LIST_UPDATED = 'footnotes_list_updated';
export const FOOTNOTES_DIFFICULTY_UPDATE = 'footnotes_difficulty_update';
export const FOOTNOTES_SPECIALTY_UPDATE = 'footnotes_specialty_update';
export const FOOTNOTE_EDITED = 'footnote_edited';
export const FOOTNOTE_REMOVED = 'footnote_removed';

export const SET_ELAPSED_TIME = 'set_elapsed_time';
export const SET_LAST_SAVED_TIME = 'set_last_saved_time';

export const SUBMISSION_LOADED = 'submission_loaded';
export const SUBMISSION_UPLOADED = 'submission_uploaded';
export const SUBMISSION_LOAD_FAILURE = 'submission_load_failure';
export const SUBMISSION_UPLOAD_FAILURE = 'submission_upload_failure';

export const SET_ASSIGNMENT = 'set_assignment';
export const SET_ASSIGNMENT_NAME = 'set_assignment_name';
export const ASSIGNMENT_RENAME_FAILURE = 'assignment_rename_failure';

export const SET_TEDIT_FAILURE = 'set_tedit_failure';

export const MED_DICT_ADDED = 'med_dict_added';

export const TOGGLE_SHOW_MODAL = 'toggle_show_modal';

export const SET_MODAL_CONTENT = 'set_modal_content';

export const KEYBOARD_SHORTCUTS_ADDED = 'keyboard_shortcuts_added';

export const RESET_SAVE_STATE = 'reset_save_state';

export const SHOW_BLUR_NOTICE = 'show_blur_notice';

export const SET_CURRENT_AUDIO_TIME_DISPLAY = 'set_current_time';
export const SET_AUDIO_DURATION = 'set_audio_duration';
export const SET_PLAYBACK_RATE_DISPLAY = 'set_current_audio_rate';

export const SET_FLASH_MESSAGE = 'set_flash_message';
