import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setModalContent, setShowModal} from "../actions/setup";
import GradingModal from "./GradingModal";

const Header = () => {
  const {
    footnotes,
    assignment,
  } = useSelector((state) => state.setup);

  const dispatch = useDispatch();
  const handleShowModal = () => {
    dispatch(setModalContent({
      title: 'How Grading Works',
      body: GradingModal(),
    }));
    dispatch(setShowModal());
  };

  return (
    <ul className="assignment-header">
      <li>
        <span className="title">Difficulty Level</span><span className="value">{footnotes && footnotes.difficulty}</span>
      </li>
      <li>
        <span className="title">Specialty</span><span className="value">{footnotes && footnotes.specialty}</span></li>
      {assignment.isUserAdmin && <li>
        <span className="title">Possible Points</span><span className="value">{assignment.points}</span></li> }
      <li className="right" onClick={handleShowModal}><a>How grading works</a></li>
    </ul>
  );
};

export default Header;
