/*
 * Copyright 2020 3M Company. This source code file contains proprietary information of 3M.
 */

import {
  SUBMIT_ENTRY,
  EDIT_ENTRY,
  TEDIT_ADDED,
  TEMPLATE_LOADED,
  TEMPLATE_LOAD_FAILURE,
  SOLUTION_LOADED,
  SOLUTION_LOAD_FAILURE,
  FOOTNOTES_LOADED,
  FOOTNOTES_LOAD_FAILURE,
  FOOTNOTES_SAVE_FAILURE,
  FOOTNOTES_EXTENDED,
  FOOTNOTES_LIST_UPDATED,
  FOOTNOTES_DIFFICULTY_UPDATE,
  FOOTNOTES_SPECIALTY_UPDATE,
  FOOTNOTE_EDITED,
  FOOTNOTE_REMOVED,
  SUBMISSION_LOADED,
  SET_ELAPSED_TIME,
  SET_TEDIT_FAILURE,
  MED_DICT_ADDED,
  TOGGLE_SHOW_MODAL,
  SET_MODAL_CONTENT,
  SET_ASSIGNMENT,
  SET_ASSIGNMENT_NAME,
  SET_CURRENT_AUDIO_TIME_DISPLAY,
  SET_PLAYBACK_RATE_DISPLAY,
  SET_AUDIO_DURATION,
  SET_FLASH_MESSAGE,
  SET_LAST_SAVED_TIME,
} from '../actions/types';

import {
  ENTRY,
  SUBMISSION,
  ANSWERKEY,
} from '../constants';

export const INITIAL_STATE = {
  template: null,
  templateError: null,
  solution: null,
  solutionError: null,
  submission: null,
  entryTedit: null,
  submissionTedit: null,
  answerkeyTedit: null,
  footnotes: null,
  footnotesError: null,
  elapsedTime: 0,
  lastSavedTime: 0,
  teditError: null,
  medWordsAdded: [],
  showModal: false,
  showBlurNotice: true,
  modalContent: '',
  modalTitle: '',
  assignment: {},
  currentAudioRate: 1,
  currentAudioTime: 0,
  audioDuration: 0,
  flashMessage: null,
};

const { $ } = window;

export default (state = INITIAL_STATE, action) => {
  let returnState = state;
  switch (action.type) {
    case SUBMIT_ENTRY:
      if (state.entryTedit) {
        state.entryTedit.getEditor().unbind('keyup');
        state.entryTedit.getEditor().unbind('keydown');
        if ($) {
          $(document).unbind('keyup');
          $(document).unbind('keydown');
        }
      }
      return {
        ...state,
        entry: null,
        entryTedit: null,
        submission: '',
      };
    case EDIT_ENTRY:
      if (state.submissionTedit) {
        state.submissionTedit.getEditor().unbind('keyup');
        state.submissionTedit.getEditor().unbind('keydown');
        if ($) {
          $(document).unbind('keyup');
          $(document).unbind('keydown');
        }
      }
      return {
        ...state,
        submissionTedit: null,
        answerkeyTedit: null,
        template: null,
      };
    case TEDIT_ADDED:
      if (action.payload.type === ENTRY) {
        returnState = { ...state, entryTedit: action.payload.instance };
      } else if (action.payload.type === SUBMISSION) {
        returnState = { ...state, submissionTedit: action.payload.instance };
      } else if (action.payload.type === ANSWERKEY) {
        returnState = { ...state, answerkeyTedit: action.payload.instance };
      }
      return returnState;
    case SET_ASSIGNMENT:
      return { ...state, assignment: action.payload };
    case SET_ASSIGNMENT_NAME:
      return { ...state, assignment: { ...(state.assignment || {}), name: action.payload } };
    case TEMPLATE_LOADED:
      return { ...state, template: action.payload, templateError: null };
    case TEMPLATE_LOAD_FAILURE:
      return { ...state, template: null, templateError: action.payload };
    case SOLUTION_LOADED:
      return { ...state, solution: action.payload, solutionError: null };
    case SOLUTION_LOAD_FAILURE:
      return { ...state, solution: null, solutionError: action.payload };
    case FOOTNOTES_LOADED:
      return { ...state, footnotes: action.payload, footnotesError: null };
    case FOOTNOTES_LOAD_FAILURE:
    case FOOTNOTES_SAVE_FAILURE:
      return { ...state, footnotesError: action.payload, footnotes: null };
    case FOOTNOTES_LIST_UPDATED:
      return { ...state, footnotes: { ...(state.footnotes || {}), footnotes: action.payload } };
    case FOOTNOTES_DIFFICULTY_UPDATE:
      return { ...state, footnotes: { ...(state.footnotes || {}), difficulty: action.payload } };
    case FOOTNOTES_SPECIALTY_UPDATE:
      return { ...state, footnotes: { ...(state.footnotes || {}), specialty: action.payload } };
    case FOOTNOTES_EXTENDED:
      const prevFootnotes = state.footnotes || {};
      const prevFootnotesList = prevFootnotes.footnotes || [];
      return { ...state, footnotes: {...prevFootnotes, footnotes: [...prevFootnotesList, ...action.payload]}};
    case FOOTNOTE_EDITED:
      const footnotes = ((state.footnotes || {}).footnotes || []);
      footnotes.splice(action.payload.index, 1, action.payload.value);
      return { ...state, footnotes: { ...(state.footnotes || {}), footnotes } };
    case FOOTNOTE_REMOVED:
      const footnotesRemoved = ((state.footnotes || {}).footnotes || []);
      footnotesRemoved.splice(action.payload.index, 1)
      return { ...state, footnotes: { ...(state.footnotes || {}), footnotes: footnotesRemoved } };
    case SUBMISSION_LOADED:
      return { ...state, submission: action.payload };
    case SET_ELAPSED_TIME:
      return { ...state, elapsedTime: action.payload };
    case SET_LAST_SAVED_TIME:
      return { ...state, lastSavedTime: action.payload };
    case SET_TEDIT_FAILURE:
      return { ...state, teditError: 'Could not load the content editor.' };
    case MED_DICT_ADDED:
      return { ...state, medWordsAdded: action.payload };
    case TOGGLE_SHOW_MODAL:
      return { ...state, showModal: !state.showModal };
    case SET_MODAL_CONTENT:
      return { ...state, modalContent: action.payload.body, modalTitle: action.payload.title };
    case SET_CURRENT_AUDIO_TIME_DISPLAY:
      return { ...state, currentAudioTime: action.payload };
    case SET_PLAYBACK_RATE_DISPLAY:
      return { ...state, currentAudioRate: action.payload };
    case SET_FLASH_MESSAGE:
      return { ...state, flashMessage: action.payload };
    case SET_AUDIO_DURATION:
      return { ...state, audioDuration: action.payload };
    default:
      return { ...state };
  }
};
