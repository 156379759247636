import React from 'react';
import { useSelector } from "react-redux";

const FlashMessage = () => {
  const {
    flashMessage,
  } = useSelector((state) => state.setup);

  return (
    <div>
      { flashMessage && <div className='save-popup'>
        {flashMessage}
      </div> }
    </div>
  );
};

export default FlashMessage;
