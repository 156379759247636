/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ThinEditor from './ThinEditor';
import Footnotes from './Footnotes';

import {
  submitEntry,
  sendGradeToCanvas,
  saveTemplate,
  saveSolution,
  renameAssignment,
} from '../actions/submission';

import {
  loadTemplate,
  loadSolution,
  loadSubmission,
  setupMedicalDictionary,
  setupKeyboardShortcuts,
  setShowModal,
  setModalContent,
  saveFootnotes,
  extendFootnotes,
  resizeIFrameStudentEntry,
  resizeIFrameAdminEntry,
  handleAudioLoaded,
  loadTemplateContent,
  attachAudio,
} from '../actions/setup';

import {ENTRY} from '../constants';
import BlurNotice from "./BlurNotice";
import AudioMetaBar from "./AudioMetaBar";
import LoadSpinner from "./LoadSpinner";
import FlashMessage from "./FlashMessage";
import {SET_ELAPSED_TIME, SET_LAST_SAVED_TIME} from "../actions/types";

const { $ } = window;

/**
 * React component used to display the entry ThinEditor in which
 * the student listened to audio and enters their solutions.
 */
const Entry = () => {
  const dispatch = useDispatch();
  const { canvas_assignment } = window;
  const {
    entryTedit,
    template,
    solution,
    submission,
    footnotes,
    assignment,
    elapsedTime,
    lastSavedTime,
  } = useSelector((state) => state.setup);

  const { submissionContent, successfulSave } = useSelector((state) => state.submission);

  const [footPedalHotkeysAdded, setFootPedalHotkeysAdded] = useState(false);
  const [viewingAnswer, setViewingAnswer] = useState(false);
  const [tempWork, setTempWork] = useState('');
  const [documentDirty, setDocumentDirty] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [lastSuperScript, setLastSuperScript] = useState(1);
  const [audioCallbackAdded, setAudioCallbackAdded] = useState(false);

  const resetDocument = async () => {
    const audioControl = entryTedit.getDocument().getAudioControl();
    if (audioControl) {
      audioControl.stop();
    }
    await loadTemplateContent(entryTedit, template);
    await attachAudio(entryTedit, assignment.name);
    dispatch(setupKeyboardShortcuts(entryTedit));
    setStartTime(Date.now());
    dispatch({ type: SET_ELAPSED_TIME, payload: 0 });
  };

  const increaseElapsedTime = () => {
    const nowTime = Date.now();

    const totalElapsedTime = elapsedTime + (nowTime - startTime);

    dispatch({ type: SET_ELAPSED_TIME, payload: totalElapsedTime });
    setStartTime(nowTime);

    return totalElapsedTime;
  };

  const saveProgress = async () => {
    const newElapsedTime = increaseElapsedTime();
    const timeNow = Date.now();
    const gradeContent = {
      statistics: { points: 100 },
      submission: await entryTedit.export(),
    };

    const snapshot = $('html').clone();
    snapshot.find('cda-clinicaldocument').prepend(`<div id="entry-data" data-last-saved=${timeNow} data-elapsed-time=${newElapsedTime}/>`);

    dispatch(sendGradeToCanvas('InProgress', 'NotReady', gradeContent, snapshot, assignment.link, assignment.sessionId));
    dispatch({ type: SET_LAST_SAVED_TIME, payload: timeNow });
  }

  const addSuperScript = (lastValue) => {
    let value = lastValue || 1;
    const cursor = entryTedit.getCursorContext();
    const textEntry = (
      <>
      <div>Which Footnote?</div>
    <input id='addFootnoteIndex' type='number' onChange={(e) => {
      value = e.target.value;
      setLastSuperScript(value);
    }} />
      <div>
      <button
    type='button'
    className='btn btn-default btn-submit'
    style={{ width: '150px' }}
    onClick={() => {
      if (cursor) {
        cursor.insertCdaHtml(`<cda-sup>${value}</cda-sup>`);
        dispatch(setShowModal());
      }
    }}
  >
    Submit
    </button>
    </div>
    </>
    );
    dispatch(setShowModal());
    dispatch(setModalContent({
      title: 'Add footnote',
      body: textEntry,
    }));
  };

  const addFootnote = () => {
    let footnoteVal = '';
    const textEntry = (
      <>
        <div>Footnotes text</div>
        <textarea id='addFootnoteTextArea' onChange={(e) => {
          footnoteVal = e.target.value;
        }} rows='15' cols='60' />
        <div>
          <button
            type='button'
            className='btn btn-default btn-submit'
            style={{ width: '150px' }}
            onClick={() => {
              $('#addFootnoteTextArea').val('');
              dispatch(extendFootnotes(footnoteVal));
              dispatch(setShowModal());
            }}
          >
            Submit footnote
          </button>
        </div>
      </>
    );
    dispatch(setShowModal());
    dispatch(setModalContent({
      title: 'Add footnote',
      body: textEntry,
    }));
  };

  useLayoutEffect(() => {
    if (assignment.isUserAdmin) {
      resizeIFrameAdminEntry(assignment.postMessageToken);
    } else {
      resizeIFrameStudentEntry(assignment.postMessageToken);
    }
  });

  useEffect(() => {
    if (!template) {
      dispatch(loadTemplate(canvas_assignment.name));
    }

    if (submission === null) {
      dispatch(loadSubmission(canvas_assignment.name, canvas_assignment.link, canvas_assignment.sessionId));
    }

    if (!solution) {
      dispatch(loadSolution(canvas_assignment.name));
    }

    if (entryTedit && !audioCallbackAdded) {
      entryTedit.onAudioLoaded(() => {
        dispatch(handleAudioLoaded(entryTedit));
        setAudioCallbackAdded(true);
      });
    }

    if (entryTedit && !footPedalHotkeysAdded) {
      // Remove spellcheck from toolbar
      $('.btn-toolbar').find('button[title|="Spell Check"]').parent().remove();
      // Setup medical dictionary
      dispatch(setupMedicalDictionary(entryTedit));
      // setup keyboard shortcuts for foot pedal
      setFootPedalHotkeysAdded(true);
      entryTedit.onAudioLoaded(() => {
        dispatch(setupKeyboardShortcuts(entryTedit));
      });

      entryTedit.onDocumentChanged((e) => {
        if (!documentDirty) {
          setDocumentDirty(true);
        }

        const cursor = entryTedit.getCursorContext();
        if (!cursor) {
          return;
        }
        const current = cursor.getCurrentContent();
        if (current && current.element.nodeName === 'CDA-CONTENT') {
          current.mergeSiblings();
        }
      });
    }

    if (assignment.isUserAdmin && entryTedit) {
      const hotkey = entryTedit.hotkeys().create({
        keys: 'mod+i',
        description: 'Add tooltip',
        callback: () => addSuperScript(lastSuperScript),
      });
      entryTedit.hotkeys().add(hotkey);
    }

    if (successfulSave) {
      setDocumentDirty(false);
    }
  }, [
    template,
    solution,
    entryTedit,
    footPedalHotkeysAdded,
    dispatch,
    submission,
    successfulSave,
    lastSuperScript,
  ]);

  return (
    <div id="entry">
      { (successfulSave || successfulSave === false)
      && <div className='save-popup'>
        {successfulSave ? 'Successfully Saved' : 'Save Failed' }
      </div>
      }
      <FlashMessage/>
      <BlurNotice/>
      {entryTedit
        ? <AudioMetaBar audio={entryTedit && entryTedit.getDocument().getAudioControl()}/>
        : <LoadSpinner title="assignment"/>
      }
      {assignment.isUserAdmin
      && <>
        <h3 style={{ 'margin-left': '10px' }}>{viewingAnswer ? 'Key' : 'Template'}</h3>
        <button
          type='button'
          className='btn btn-default btn-action'
          onClick={async () => {
            const audioControl = entryTedit.getDocument().getAudioControl();
            if (audioControl) {
              audioControl.stop();
            }

            const oldContent = await entryTedit.export();

            let newContent;
            if (!viewingAnswer) {
              newContent = tempWork || solution;
              entryTedit.getDocument().$element.css('background-color', '#dfdddd');
              setViewingAnswer(true);
            } else {
              newContent = tempWork || template;
              setViewingAnswer(false);
            }

            await loadTemplateContent(entryTedit, newContent);

            setTempWork(oldContent);

            attachAudio(entryTedit, assignment.name);
          }}
        >{ viewingAnswer ? 'Show Template' : 'Show Key' }</button>
        <button
          type='button'
          className='btn btn-default btn-action'
          style={{ color: 'blue' }}
          onClick={async () => {
            if (window.confirm(`Are you sure you want to overwrite the existing ${ viewingAnswer ? 'Key' : 'Template'}?`)) {
              const sub = await entryTedit.export();
              const saveRequest = viewingAnswer ? saveSolution(sub, assignment.name) : saveTemplate(sub, assignment.name);
              dispatch(saveRequest);
            }
          }}
        >{viewingAnswer ? 'Save Key' : 'Save Template'}</button>
        <button
          type='button'
          className='btn btn-default btn-action'
          onClick={async () => {
            const newName = window.prompt('What is the new name of the assignment?\n\nName must be unique.')
            if (newName != null) {
              dispatch(renameAssignment(newName, assignment.name, assignment.id, assignment.courseId));
            }
          }}
        >Rename</button>
      </>
      }
      <div id="entryEditor">
        {template && submission !== null
          && <ThinEditor
            elName="edit"
            content={ submissionContent || (submission || template) }
            audio={ `${assignment.name || canvas_assignment.name}` }
            teditInstance={ ENTRY }
            loadWhen={ true }
            useToolbar={ true }
            readonly={ false }
            preventCopy={ true }
            spellCheckEnabled={ true }
          />
        }
      </div>
      <div style={{ float: 'right' }} role='group'>
        <button
          type='button'
          className='btn btn-default btn-action'
          onClick={resetDocument}
        >Reset Document</button>
        {!assignment.isUserAdmin && <>
          <button
            disabled={!documentDirty}
            type='button'
            className='btn btn-default btn-action btn-submit'
            style={{ width: '165px' }}
            onClick={saveProgress}
          >{documentDirty ? 'Save' : `Last Saved ${new Date(lastSavedTime).toLocaleTimeString()}` }</button>
          <button
            type='button'
            className='btn btn-primary btn-action btn-submit'
            onClick={() => {
              increaseElapsedTime();
              dispatch(submitEntry(entryTedit));
            }}
          >Submit</button>
          </>
        }
      </div>
      {assignment.isUserAdmin && footnotes && <div>
        <h4 style={{ 'margin-left': '10px' }} >Footnotes</h4>
        <button
          type='button'
          className='btn btn-default btn-action'
          onClick={async () => {
            addFootnote();
          }}
        >Add Footnote</button>
        <button
          type='button'
          className='btn btn-default btn-action'
          style={{ color: 'blue' }}
          onClick={async () => {
            if (window.confirm('Are you sure, this will replace the current footnotes.')) {
              dispatch(saveFootnotes(footnotes, assignment.name));
            }
          }}
        >Save Footnotes</button>
        <Footnotes items={footnotes.footnotes} difficulty={footnotes.difficulty} specialty={footnotes.specialty}/>
      </div>}
    </div>
  );
};

export default Entry;
