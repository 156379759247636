/*
 * Copyright 2020 3M Company. This source code file contains proprietary information of 3M.
 */
import Mousetrap from 'mousetrap';

import {
  TEDIT_ADDED,
  TEMPLATE_LOADED,
  SOLUTION_LOADED,
  FOOTNOTES_LOADED,
  FOOTNOTES_LOAD_FAILURE,
  FOOTNOTES_EXTENDED,
  FOOTNOTES_LIST_UPDATED,
  FOOTNOTES_DIFFICULTY_UPDATE,
  FOOTNOTES_SPECIALTY_UPDATE,
  FOOTNOTE_EDITED,
  FOOTNOTE_REMOVED,
  SUBMISSION_LOADED,
  SET_ELAPSED_TIME,
  SUBMISSION_LOAD_FAILURE,
  SOLUTION_LOAD_FAILURE,
  TEMPLATE_LOAD_FAILURE,
  SET_TEDIT_FAILURE,
  MED_DICT_ADDED,
  EDIT_ENTRY,
  TOGGLE_SHOW_MODAL,
  SET_MODAL_CONTENT,
  KEYBOARD_SHORTCUTS_ADDED,
  SUBMIT_ENTRY,
  SET_ASSIGNMENT,
  SHOW_BLUR_NOTICE,
  SET_CURRENT_AUDIO_TIME_DISPLAY,
  SET_PLAYBACK_RATE_DISPLAY,
  SET_AUDIO_DURATION,
  SET_FLASH_MESSAGE,
  FOOTNOTES_SAVE_FAILURE,
  SET_LAST_SAVED_TIME,
} from './types';

import { SUBMISSION_KEY, LOCAL_STORAGE_STEP_BACK, FLASH_MESSAGE_DURATION, REACT_APP_SOLUTION_SALT } from '../constants';
import medicalDictionary from '../medicalDictionary.json';

const { $ } = window;

const xmlTransformOptions = (api) => {
  return [
    // Transform from the CDA R2-beta2 format to CDA R2
    api.transforms().customXsltFactory('../UpdateCDA_r2b2_to_r2.xslt'),
    // Standard transform from CDA to HTML
    api.transforms().cdaToHtml,
  ];
};

const shouldTransformXML = (content) => {
  return /<bodyChoice>/g.test(content);
};

export const loadTemplateContent = (api, content) => {
  const options = {};
  if (shouldTransformXML(content)) {
    options.transform = xmlTransformOptions(api);
  }
  return api.import(content, options);
};

export const attachAudio = (api, name) => {
  return api.getDocument().attachAudio(`/audio?name=${name}`)
    .catch((e) => {
      console.log(`failed to attach audio for : ${name}`, e);
    });
};

// -- encryption with no dependencies found in https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption
const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return encoded => encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
};

const myDecipher = decipher(REACT_APP_SOLUTION_SALT);

/**
 * Method for loading the template into the solution.
 * @param {String} name - assignment.name
 */
export const loadTemplate = (name) => {
  return async (dispatch) => {
    try {
      const contentResp = await fetch(
        `/template?name=${name}`,
        {
          method: 'get',
          credentials: 'include',
        },
      );
      const { content } = await contentResp.json();
      const payload = content;
      dispatch({ type: TEMPLATE_LOADED, payload });
    } catch (e) {
      dispatch({ type: TEMPLATE_LOAD_FAILURE, payload: e.message });
    }
  };
};

/**
 * Method for loading the user's previous submission
 * @param {String} name - assignment.name
 * @param {String} link - assignment.link
 */
export const loadSubmission = (name, link, sessionId) => {
  return async (dispatch) => {
    try {
      const prevSubmissionResp = await fetch(
        '/resultsPassback',
        {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            link,
            sessionId,
          }),
        },
      );
      const prevSubmissionJson = await prevSubmissionResp.json();
      let payload = '';
      if (prevSubmissionJson.length > 0 && prevSubmissionJson[0][SUBMISSION_KEY]) {
        const htmlData = prevSubmissionJson[0][SUBMISSION_KEY].submission_data;
        const base64EncodedPayload = $(htmlData).find('#encodedSubmission').text();

        const $entryData = $(htmlData).find('#entry-data');
        if ($entryData) {
          dispatch({ type: SET_ELAPSED_TIME, payload: ($entryData.data('elapsed-time') || 0) });
          dispatch({ type: SET_LAST_SAVED_TIME, payload: ($entryData.data('last-saved') || 0) });
        }

        payload = Buffer.from(base64EncodedPayload, 'base64').toString('utf8');

        if (prevSubmissionJson[0].resultScore) {
          dispatch({
            type: SUBMIT_ENTRY,
            payload: {
              content: payload,
              shouldGrade: false,
            },
          });
        } else {
          dispatch({ type: SUBMISSION_LOADED, payload });
        }


      } else {
        dispatch({ type: SUBMISSION_LOADED, payload });
      }
    } catch (e) {
      dispatch({ type: SUBMISSION_LOAD_FAILURE, payload: e.message });
    }
  };
};

/**
 * Method for loading the solution into the application.
 * @param {String} name - assignment.name
 */
export const loadSolution = (name) => {
  return async (dispatch) => {
    try {
      const solutionResp = await fetch(
        `/solution?name=${name}`,
        {
          method: 'get',
          credentials: 'include',
        },
      );
      if (solutionResp.status !== 200) {
        const payload = await solutionResp.text();
        dispatch({ type: SOLUTION_LOAD_FAILURE, payload });
      } else {
        const body = await solutionResp.text();
        const solution = JSON.parse(myDecipher(body));
        const payload = solution.content;
        dispatch({ type: SOLUTION_LOADED, payload });
      }
    } catch (e) {
      dispatch({ type: SOLUTION_LOAD_FAILURE, payload: e.message });
    }
  };
};

/**
 * Method for loading the footnotes file.
 * @param {String} name - assignment.name
 */
export const loadFootNotes = (name) => {
  return async (dispatch) => {
    try {
      const contentResp = await fetch(
        `/footnotes?name=${name}`,
        {
          method: 'get',
          credentials: 'include',
        },
      );
      if (contentResp.status !== 200) {
        const payload = await contentResp.text();
        dispatch({ type: FOOTNOTES_LOAD_FAILURE, payload });
      } else {
        const payload = await contentResp.json();
        dispatch({ type: FOOTNOTES_LOADED, payload });
      }
    } catch (e) {
      dispatch({ type: FOOTNOTES_LOAD_FAILURE, payload: e.message });
    }
  };
};

let flashTimeout = null;
export const setFlashMessage = (message, duration = FLASH_MESSAGE_DURATION) => {
  return (dispatch) => {
    dispatch({ type: SET_FLASH_MESSAGE, payload: message });
    if (flashTimeout) {
      clearTimeout(flashTimeout);
    }
    flashTimeout = setTimeout(() => {
      dispatch({ type: SET_FLASH_MESSAGE, payload: null });
    }, duration);
  };
};

/**
 *  Save Footnotes
 * @param {Object} footnotes - Object of the footnotes
 * @param {String} name - assignment.name
 */
export const saveFootnotes = (footnotes, name) => {
  return async (dispatch) => {
    try {
      const request = {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          footnotes,
        }),
      };

      const contentResp = await fetch(
        '/footnotes',
        request,
      );

      if (contentResp.status !== 200) {
        const payload = await contentResp.text();
        dispatch({ type: FOOTNOTES_SAVE_FAILURE, payload });
        dispatch(setFlashMessage('Save Failed'));
      } else {
        dispatch(setFlashMessage('Successfully Saved'));
      }
    } catch (e) {
      dispatch(setFlashMessage('Save Failed'));
      dispatch({ type: FOOTNOTES_SAVE_FAILURE, payload: e.message });
    }
  };
};

/**
 * Method for replacing footnotes
 * @param {Array} footnotes - Footnotes to add
 */
export const updateFootnotes = (footnotes) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTES_LIST_UPDATED, payload: footnotes });
  };
};

/**
 * Method for replacing footnote difficulty
 * @param {String} difficulty
 */
export const updateFootnotesDifficulty = (difficulty) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTES_DIFFICULTY_UPDATE, payload: difficulty });
  };
};

/**
 * Method for replacing footnote specialty
 * @param {String} specialty
 */
export const updateFootnotesSpecialty = (specialty) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTES_SPECIALTY_UPDATE, payload: specialty });
  };
};

/**
 * Method for extending footnotes
 * @param {String} footnote - Footnote to add
 */
export const extendFootnotes = (footnote) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTES_EXTENDED, payload: [footnote] });
  };
};

export const editFootnote = (value, index) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTE_EDITED, payload: { value, index } });
  };
};

export const removeFootnote = (index) => {
  return async (dispatch) => {
    dispatch({ type: FOOTNOTE_REMOVED, payload: { index } });
  };
};

/**
 * Action to store a created ThinEditor instance, which will be used in various
 *  portions of the application.
 * @param {ThinEditor} instance - The ThinEditor instance to save.
 * @param {String} type - The string name of the location in which the ThinEditor
 *  instance was added. options are entry, submission, or answerkey.
 */
export const setTedit = (instance, type) => {
  return (dispatch) => {
    dispatch({ type: TEDIT_ADDED, payload: { instance, type } });
  };
};

/**
 * Action to denote a failure when setting a thin editor instance.
 */
export const setTeditFailure = () => {
  return (dispatch) => {
    dispatch({ type: SET_TEDIT_FAILURE });
  };
};

/**
 * Action to set up the medical dictionary in the entry thin editor instance.
 * @param {ThinEditor} entryTedit - Entry Thin Editor version.
 */
export const setupMedicalDictionary = (entryTedit) => {
  return (dispatch) => {
    const medicalWords = Object.keys(medicalDictionary);
    const addedWords = [];
    for (let i = 0; i < medicalWords.length; i++) {
      if (!entryTedit.spellCheck().getSpellCheckImplementation().correct(medicalWords[i])) {
        addedWords.push(medicalWords[i]);
      }
    }
    entryTedit.spellCheck().setSpellCheckerUserDictionary(medicalWords);
    entryTedit.spellCheck().validateDocument(true);
    dispatch({ type: MED_DICT_ADDED, payload: addedWords });
  };
};

export const returnToEditSubmission = () => {
  return (dispatch) => {
    dispatch({ type: EDIT_ENTRY });
  };
};

export const setModalContent = (content) => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_CONTENT, payload: content });
  };
};

export const setAssignment = (assignment) => {
  return (dispatch) => {
    dispatch({ type: SET_ASSIGNMENT, payload: assignment });
  };
};

export const setShowModal = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_SHOW_MODAL });
  };
};

export const setShowBlurNotice = (showNoticeState) => {
  return (dispatch) => {
    dispatch({ type: SHOW_BLUR_NOTICE, payload: showNoticeState });
  };
};

export const addShowBlurNoticeListeners = () => {
  return (dispatch) => {
    function windowBlurred(e) {
      if (e.target === window) {
        dispatch(setShowBlurNotice(true));
      }
    }

    function windowFocused(e) {
      if (e.target === window) {
        dispatch(setShowBlurNotice(false));
      }
    }

    function windowClicked(e) {
      dispatch(setShowBlurNotice(false)); // -- If a user clicks and triggers 'focus' before the app loads this ensures we hide the notice.
      window.removeEventListener( 'click', windowClicked, true);
    }

    window.addEventListener( 'focus',  windowFocused, true);
    window.addEventListener(  'blur',  windowBlurred, true);
    window.addEventListener( 'click',  windowClicked, true);
  }
}

export const stepAudioBack = (ac) => {
  const time = ac.getTime();
  const step = localStorage.getItem(LOCAL_STORAGE_STEP_BACK) || 0;

  if (step) {
    ac.setTime(time - step);
  }
};

export const setAudioDuration = (time) => {
  return (dispatch) => {
    dispatch({ type: SET_AUDIO_DURATION, payload: time });
  };
};

export const setCurrentAudioTimeDisplay = (time) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_AUDIO_TIME_DISPLAY, payload: time });
  };
};

export const throttle = (callback, limit) => {
  let waiting = false;
  return function () {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  }
}

export const setPlaybackRate = (ac, newRate) => {
  let displayRate;
  if (newRate) {
    ac.setPlaybackRate(newRate);
    displayRate = newRate;
  } else {
    displayRate = ac.getPlaybackRate();
  }

  return (dispatch) => {
    dispatch({ type: SET_PLAYBACK_RATE_DISPLAY, payload: displayRate });
    if (newRate) {
      const message = `Playback Speed: ${parseFloat(displayRate * 100).toFixed(0)}%`;
      dispatch(setFlashMessage(message));
    }
  };
};

export const handleAudioRateIncrease = (ac) => {
  return (dispatch) => {
    return () => {
      let rate = ac.getPlaybackRate();
      const change = rate + 0.15;
      const max = 4;
      rate = Math.min(max, change);
      dispatch(setPlaybackRate(ac, rate));
    };
  };
};

export const handleAudioRateDecrease = (ac) => {
  return (dispatch) => {
    return () => {
      let rate = ac.getPlaybackRate();
      const change = rate - 0.15;
      const min = 0.55;
      rate = Math.max(min, change);
      dispatch(setPlaybackRate(ac, rate));
    };
  };
};

export const handleAudioLoaded = (TEdit) => {
  const ac = TEdit.getDocument().getAudioControl();
  return (dispatch) => {
    dispatch(setAudioDuration(ac.element.duration));
    dispatch(setCurrentAudioTimeDisplay(0));
    dispatch(setPlaybackRate(ac));
    ac.event.on('timeUpdated', throttle((currentAudioTime) => dispatch(setCurrentAudioTimeDisplay(currentAudioTime)), 100));
    ac.event.on('stop', () => dispatch(setCurrentAudioTimeDisplay(0)));
    ac.event.on('pause', (el) => dispatch(setCurrentAudioTimeDisplay(el.getTime())));

    const audioDecreaseRateHotkey = TEdit.hotkeys().get('audioDecreaseRate');
    const audioIncreaseRateHotkey = TEdit.hotkeys().get('audioIncreaseRate');
    const audioResetRateHotkey = TEdit.hotkeys().get('audioResetRate');
    audioDecreaseRateHotkey.setCallback(dispatch(handleAudioRateDecrease(ac)));
    audioIncreaseRateHotkey.setCallback(dispatch(handleAudioRateIncrease(ac)));
    audioResetRateHotkey.setCallback(() => dispatch(setPlaybackRate(ac, 1)));
  };
};

export const setupKeyboardShortcuts = (teditInstance) => {
  const ac = teditInstance.getDocument().getAudioControl();

  return (dispatch) => {
    // First remove any old event listeners
    $(document).unbind('keyup');
    $(document).unbind('keydown');
    teditInstance.getEditor().unbind('keyup');
    teditInstance.getEditor().unbind('keydown');

    // --------  Bind these callbacks to the 'Document' of the iFrame.  There are similar callbacks being used by the
    // --------  ThinEditor if the mouse focus is within the Text Input Box.  These callbacks are ignored if the mouse
    // --------  focus is inside the ThinEditor.
    // -- Reset any callbacks
    Mousetrap.reset();
    // -- Toggle Play
    Mousetrap.bind(['mod+space'], () => {
      ac.togglePlayback();
    }, 'keydown');

    // -- Stop and rewind
    Mousetrap.bind(['mod+alt+,'], () => {
      ac.setTime(0);
    });

    // -- Toggle rewind
    Mousetrap.bind(['mod+,'], () => {
      ac.toggleRewind();
    });

    // -- Toggle fast foward
    Mousetrap.bind(['mod+.'], () => {
      ac.toggleFastForward();
    });

    // -- Increase audio rate
    Mousetrap.bind(['mod+alt+='], () => {
      dispatch(handleAudioRateIncrease(ac))();
    });

    // -- Decrease audio rate
    Mousetrap.bind(['mod+alt+d', 'mod+alt+-'], () => {
      dispatch(handleAudioRateDecrease(ac))();
    });

    // -- Set playback rate to normal speed
    Mousetrap.bind(['mod+alt+0'], () => {
      dispatch(setPlaybackRate(ac, 1));
    });

    // -- prevent Copy/Paste/Cut
    Mousetrap.bind(['ctrl+c', 'command+c', 'ctrl+v', 'command+v', 'ctrl+x', 'command+x'], (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }

      return false;
    });

    $('cda-clinicaldocument').on('copy paste cut', (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }

      return false;
    });

    const audioDecreaseRateHotkey = teditInstance.hotkeys().get('audioDecreaseRate');
    audioDecreaseRateHotkey.setKeys(audioDecreaseRateHotkey.getKeys().concat(['ctrl+alt+d']));

    const audioFastForwardToggle = teditInstance.hotkeys().get('audioFastForward');
    audioFastForwardToggle.setDescription("Fast Forward audio");

    let debounceDone = true;
    const debounce = () => {
      debounceDone = false;
      setTimeout(() => {
        debounceDone = true;
      }, 50);
    };
    const keydownHandler = (e) => {
      if (!debounceDone) {
        e.stopPropagation();
        return false;
      }

      const code = e.keyCode || e.which;
      if (code === 114) {
        debounce();
        ac.toggleRewind();
      } else if (code === 115) {
        debounce();
        ac.play();
      } else if (code === 116) {
        debounce();
        ac.fastForward();
      }

      if (code === 114 || code === 115 || code === 116) {
        e.stopPropagation();
        return false;
      }
    };

    const keyupHandler = (e) => {
      if (!debounceDone) {
        e.stopPropagation();
        return false;
      }
      const code = e.keyCode || e.which;
      if (code === 114 || code === 115 || code === 116) {
        ac.pause();
        debounce();
        e.stopPropagation();

        if (code === 115) {
          stepAudioBack(ac);
        }

        return false;
      }
    };

    teditInstance.getEditor().keydown(keydownHandler);
    teditInstance.getEditor().keyup(keyupHandler);

    $(document).keyup(keyupHandler);
    $(document).keydown(keydownHandler);
    dispatch({ type: KEYBOARD_SHORTCUTS_ADDED });
  };
};

export const resizeIFrame = (height = 630, token = '') => {
  const subject = 'lti.frameResize';
  window.parent.postMessage(JSON.stringify({ subject, token, height }), '*');
}

export const resizeIFrameStudentEntry = (token) => {
  const height = 630;
  resizeIFrame(height, token);
}

export const resizeIFrameAdminEntry = (token) => {
  const height = 1000;
  resizeIFrame(height, token);
}
