import React, {Component} from 'react';
import {render} from 'react-dom';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useSelector, useDispatch } from 'react-redux';
import { updateFootnotes, editFootnote, removeFootnote, updateFootnotesDifficulty, updateFootnotesSpecialty } from '../actions/setup';
import ContentEditable from 'react-contenteditable'

class Edit extends Component {
  constructor(props){
    super(props)
    this.contentEditable = React.createRef();
    this.state = {html: props.value};
    this.index = props.index;
  }

  handleChange = evt => {
    const value = evt.target.value;
    this.setState({html: value});
  }

  handleBlur = evt => {
    this.props.dispatch(editFootnote(this.state.html, this.index))
  }

  render = () => {
    return <ContentEditable
      innerRef={this.contentEditable}
      html={this.state.html}
      disabled={false}
      onChange={this.handleChange}
      onBlur={this.handleBlur}
      tagName='article'
    />
  };
}

class Remove extends Component {
  constructor(props){
    super(props)
    this.remove = this.remove.bind(this);
  }

  remove() {
    this.props.dispatch(removeFootnote(this.props.index))
  }

  render() {
    return ( <a onClick={this.remove}>delete</a> );
  }
}


class ShowAlert extends Component {
  showAlert() {
    alert("Im an alert");
  }

  render() {
    return <button onClick={this.showAlert}>show alert</button>;
  }
}

const Footnote = SortableElement(({value, sortIndex, dispatch}) =>
  <li style={{'margin-bottom': '20px', 'border-bottom': '1px dashed black'}}>
    <Edit key={`edit-${sortIndex}`} value={value} index={sortIndex} dispatch={dispatch}/>
    <Remove key={`remove-${sortIndex}`} index={sortIndex} dispatch={dispatch}/>
  </li>
);

const FootnoteList = SortableContainer(({items, dispatch}) => {
  return (
    <ol>
    {items.map((value, index) => (
        <Footnote key={`item-${value}`} index={index} value={value} sortIndex={index} dispatch={dispatch}/>
    ))}
    </ol>
  );
});

const Footnotes = (props) => {
  const dispatch = useDispatch();

  const onSortEnd = ({oldIndex, newIndex}) => {
    dispatch(updateFootnotes(arrayMove(props.items, oldIndex, newIndex)));
  };

  const handleDifficultyChange = (event) => {
    dispatch(updateFootnotesDifficulty(event.target.value));
  };

  const handleSpecialtyChange = (event) => {
    dispatch(updateFootnotesSpecialty(event.target.value));
  };

  return (<div>
    <label className="footnotes-difficulty">
      Difficulty:
      <input value={props.difficulty} maxLength="3" onChange={handleDifficultyChange} />
    </label>
    <label className="footnotes-specialty">
      Specialty:
      <input value={props.specialty} onChange={handleSpecialtyChange} />
    </label>
    <div className="footnotes-list">
      <FootnoteList items={props.items} distance={10} onSortEnd={onSortEnd} dispatch={dispatch}/>
    </div>
  </div>)
}

export default Footnotes;
