/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { Component } from 'react';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';
import LandingPage from './components/LandingPage';

import './App.css';

/**
 * React component that is initially loaded.
 */
class App extends Component {
  render() {
    const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store}>
        <LandingPage />
      </Provider>
    );
  }
}

export default App;
