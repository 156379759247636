import React, { useState, useLayoutEffect } from 'react';
import {useSelector} from "react-redux";


const BlurNotice = () => {
  const {
    showBlurNotice,
  } = useSelector((state) => state.navigation);

  const [display, setDisplay] = useState('inline');

  useLayoutEffect(() => {
    setDisplay(showBlurNotice ? 'inline' : 'none');
  }, [showBlurNotice]);

  return (
    <div>
      <div className="modal-background" style={{ display }} />
      <div className="modal-box blur-notice" style={{ display }}>
        <div className="modal-dialog">
          <div className="blur-content">
            Click in this window to enable keyboard shortcuts and the foot pedal
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlurNotice;
