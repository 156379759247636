/*
 * Copyright 2019-2020 3M Company. This source code file contains proprietary information of 3M.
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Entry from './Entry';
import Comparison from './Comparison';
import Header from './Header';
import Modal from './Modal';
import ErrorMessage from './ErrorMessage';

import { PAGE_NAMES } from '../constants';

import {
  loadFootNotes,
  setShowModal,
  setAssignment,
  addShowBlurNoticeListeners,
} from '../actions/setup';

import { resetSaveState } from '../actions/submission';

/**
 * React component that initially loaded and allows navigation.
 */
const LandingPage = () => {
  const { currentPage } = useSelector((state) => state.navigation);
  const {
    footnotes,
    templateError,
    solutionError,
    footnotesError,
    teditError,
    showModal,
    modalContent,
    modalTitle,
    assignment,
  } = useSelector((state) => state.setup);

  const { successfulSave } = useSelector((state) => state.submission);
  const [ haveAddedListeners, setHaveAddedListeners] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const { canvas_assignment } = window;
    if (!assignment || !assignment.name) {
      dispatch(setAssignment(canvas_assignment));
    }

    if (!haveAddedListeners) {
      dispatch(addShowBlurNoticeListeners());
      setHaveAddedListeners(true);
    }

    if (!footnotes && !footnotesError) {
      dispatch(loadFootNotes(assignment.name || canvas_assignment.name));
    }

    if (successfulSave || successfulSave === false) {
      setTimeout(() => {
        dispatch(resetSaveState());
      }, 3000); // run in 3 seconds.
    }
  }, [footnotes, footnotesError, dispatch, successfulSave]);

  const hasError = templateError || solutionError || footnotesError || teditError;
  const showEntry = !hasError && currentPage === PAGE_NAMES.ENTRY;
  const showComparison = !hasError && currentPage === PAGE_NAMES.COMPARISON;

  return (
    <div id="App">
      <Modal
        showModal={showModal}
        bodyContent={modalContent}
        title={modalTitle}
        cancelFn={() => dispatch(setShowModal())}
      />
      <Header/>
      {hasError && <ErrorMessage/>}
      {showEntry && <Entry/> }
      {showComparison && <Comparison/> }
    </div>
  );
};

export default LandingPage;
